"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var Root = function () {
    var _a = __read((0, react_1.useState)(''), 2), iframeSrc = _a[0], setIframeSrc = _a[1];
    var iframe = (0, react_1.useRef)();
    (0, react_1.useEffect)(function () {
        // Temporarily removing logic to boot the user out without an active copilot session,
        // since the member app may not require one.
        //
        // if (!sessionStorage.getItem('accessToken')) {
        //   window.location.assign(`${window.location.origin}/auth/login`);
        // }
        if (sessionStorage.getItem('lastMemberApp')) {
            setIframeSrc(sessionStorage.getItem('lastMemberApp'));
        }
        else if (localStorage.getItem('isLoggedInWithIdV2') && sessionStorage.getItem('accessToken')) {
            setIframeSrc('/member/auth/sso');
        }
        else {
            setIframeSrc('/member/auth/login');
        }
        var intervalId = setInterval(function () {
            // Ideally we would be able to do the following code to sync up the broswers URL in sync with the iframe's, but
            // whenever we call `history.pushState` or `history.replaceState`, it re-renders the entire iframe. Possibly
            // related to this single-spa bug: https://github.com/single-spa/single-spa-layout/issues/123
            //
            // var newurl = window.location.protocol + "//" + window.location.host + '/lms/ + iframe?.current?.contentWindow?.location?.pathname;
            // window.history.pushState({path:newurl},'',newurl);
            //
            // So instead, we store it in local storage and use its value when the page is first loaded.
            var _a, _b, _c;
            var iframePath = (_c = (_b = (_a = iframe === null || iframe === void 0 ? void 0 : iframe.current) === null || _a === void 0 ? void 0 : _a.contentWindow) === null || _b === void 0 ? void 0 : _b.location) === null || _c === void 0 ? void 0 : _c.pathname;
            if (iframePath) {
                sessionStorage.setItem('lastMemberApp', iframePath);
            }
        }, 500);
        return function () {
            clearInterval(intervalId);
        };
    }, []);
    if (iframeSrc) {
        return (react_1.default.createElement("iframe", { ref: iframe, src: iframeSrc, "data-testid": "app-iframe", style: { overflow: 'visible', height: 'calc(100vh - 64px)', width: '100%', border: 0 } }));
    }
    return null;
};
exports.default = Root;
